import { ICatalogItem } from '../components/types'
import { elements_elements_edges_node } from '../__generated__/elements'
import { getCatalogElementUrl, getPropByCode, getPropFileByCode } from './gql'
import { getImageSrc } from './utils'

export const createCatalogElement = (
  element: elements_elements_edges_node,
  isJulireaSection?: boolean,
): ICatalogItem => {
  const newProduct =
    getPropByCode(element.propertyValuesEnum, 'NEWPRODUCT') == 'да'
  const kids = getPropByCode(element.propertyValuesEnum, 'KIDS') == 'да'
  const leader = getPropByCode(element.propertyValuesEnum, 'SALELEADER') == 'да'
  const action =
    getPropByCode(element.propertyValuesEnum, 'SPECIALOFFER') == 'да'
  const hasIcon = newProduct || leader || action || kids
  const hoverImage =
    getPropFileByCode(element.propertyValuesRelation, 'HOVER_IMAGE') || ''
  const descr = getPropByCode(element.propertyValuesEnum, 'CAPTION')
  const img =
    element.previewPicture?.url ||
    element.detailPicture?.url ||
    hoverImage ||
    ''
  const filteredSections = element.sections?.filter((el) => el.code !== 'sale')
  const hoverText =
    getPropByCode(filteredSections[0]?.propertyValues, 'UF_HOVER_TEXT') ||
    'Перейти в карточку'
  let price, priceOld
  let hasSkus = false
  let isAllSkusSale = false
  let isAllSkuSalesSimilar = true

  const isJulirea =
    filteredSections?.filter((s) => s.parentSection?.code === 'julirea')
      ?.length > 0

  const subsetsElements = element?.propertyValuesRelation?.find?.(
    (p) => p.property.code === 'SET',
  )?.targetElements

  if (element?.sku?.length > 0 || subsetsElements?.length > 0) {
    hasSkus = true

    isAllSkusSale =
      element?.sku?.every(
        (sku) => sku.priceBase !== sku.priceBaseWithDiscount,
      ) || subsetsElements?.length > 0

    element?.sku?.reduce((first, el, ind) => {
      if (ind === 0) {
        return +el.priceBaseWithDiscount || 0
      }

      if (+el.priceBaseWithDiscount !== first) {
        isAllSkuSalesSimilar = false
      }

      return first
    }, 0)

    if (!isAllSkusSale && element?.sku?.length === 1) {
      isAllSkusSale = true
    }
  }

  if (element.priceBaseWithDiscount === element.priceBase) {
    const skuPricesDiscount = element.sku?.map((s) => s.priceBaseWithDiscount)

    if (skuPricesDiscount?.some((p) => +p < +element.priceBase)) {
      priceOld = element.priceBase

      price = [...skuPricesDiscount].sort((a, b) => +a - +b)[0]
    } else {
      price = element.priceBase
    }
  } else {
    const skuLowestPrice = element?.sku
      ?.map((sku) => {
        return +sku.priceBase > +sku.priceBaseWithDiscount
          ? +sku.priceBaseWithDiscount
          : +sku.priceBase
      })
      ?.sort((a, b) => a - b)[0]

    price =
      element.priceBaseWithDiscount === element.priceBase
        ? element.priceBase
        : skuLowestPrice ?? element.priceBaseWithDiscount

    priceOld =
      element.priceBaseWithDiscount === element.priceBase
        ? null
        : element.priceBase
  }

  const parentSection = element?.sections[element.sections.length - 1]?.name

  return {
    id: element.id,
    bx_id: element.bx_id,
    name: element.name,
    code: element.code,
    img: getImageSrc(img),
    newProduct,
    kids,
    leader,
    action,
    hasIcon,
    descr,
    hoverImage: getImageSrc(hoverImage),
    hoverText,
    price,
    priceOld,
    link: getCatalogElementUrl(element, isJulirea),
    hasSkus,
    isJulirea,
    section: isJulirea ? `Julirea ${parentSection}` : parentSection,
    isAllSkusSale,
    isAllSkuSalesSimilar,
  }
}
