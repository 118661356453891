import React, { FC, useContext, useMemo, useState } from 'react'
import Link from 'next/link'

import css from './Card.module.scss'
import cx from 'classnames'
import { ICatalogItem } from '../../types'
import { PRODUCT_NO_IMAGE } from '../../data'
import Image from '../../common/Image/Image'
import { CartContext } from '../../../contexts/cart'
import Loader from '../../common/Loader/Loader'
import ym from 'react-yandex-metrika'

const Card: FC<{
  element: ICatalogItem
  category: string
  index?: number
  footerClassName?: string
  wrapperClass?: string
  hideSubcategory?: boolean
  kind?: 'grid' | 'plates'
  lazyImages?: boolean
  sizes?: string
  isGrid?: boolean
}> = ({
  element,
  index,
  wrapperClass,
  footerClassName,
  hideSubcategory,
  category,
  kind,
  lazyImages,
  sizes = '',
  isGrid,
}) => {
  const [hovered, setHovered] = useState(false)
  const { add, loading, addingProduct } = useContext(CartContext)
  const salePercent = useMemo(() => {
    if (element.priceOld && element.price) {
      return Math.round(100 - element.price / (element.priceOld / 100))
    }

    return 0
  }, [element?.price, element?.priceOld])

  return (
    <li
      className={cx(css.container, hovered && css.hovered)}
      onMouseOver={() => setHovered(true)}
      onMouseOut={() => setHovered(false)}
    >
      {!element.hasSkus && (
        <button
          type="button"
          title={`Добавить в корзину ${element.name}`}
          className={css.cartQuick}
          onClick={() => {
            if (loading) {
              return
            }

            const name = element.name
            const id = element.bx_id
            ym('reachGoal', 'km_add_to_cart_from_detail_page')

            add(
              element?.id,
              {
                name,
                id,
                price: element.price,
                quantity: 1,
                category,
              },
              1,
            )
          }}
        >
          {loading ? <Loader /> : `В корзину – ${element.price} ₽`}
        </button>
      )}
      <Link href={element.link} passHref>
        <a title={element.name}>
          <div className={cx(css.wrapper, wrapperClass)}>
            {(element.hasIcon || salePercent > 0) && (
              <div className={cx(css.icon, isGrid && css.icon_grid)}>
                {element.newProduct && (
                  <div className={css['icon-element']}>NEW</div>
                )}
                {element.kids && (
                  <div
                    className={cx(
                      css['icon-element'],
                      css['icon-element--kids'],
                    )}
                  >
                    KIDS
                  </div>
                )}
                {element.leader && (
                  <div className={css['icon-element']}>HIT</div>
                )}

                {salePercent > 0 && (
                  <div
                    className={cx(
                      css['icon-element'],
                      css['icon-element--percent'],
                    )}
                  >
                    {element.hasSkus &&
                      element.priceOld &&
                      (!element.isAllSkusSale ||
                        !element.isAllSkuSalesSimilar) &&
                      'до '}
                    -{salePercent}%
                  </div>
                )}
              </div>
            )}
            <figure className={css.poster}>
              {element.img ? (
                <Image
                  src={element.img}
                  alt={element.name}
                  width={410}
                  height={420}
                  objectFit="cover"
                  objectPosition="center"
                  loading={
                    lazyImages
                      ? 'lazy'
                      : index && index >= 0 && index < 5
                      ? 'eager'
                      : 'lazy'
                  }
                  sizes={sizes}
                />
              ) : (
                <img src={PRODUCT_NO_IMAGE} />
              )}
            </figure>
            <div className={cx(css.footer, css[kind], footerClassName)}>
              <div className={css.info}>
                <h5 className={css.title}>{element.name}</h5>
                {element.descr && !hideSubcategory && (
                  <p className={css.descr}>{element.descr}</p>
                )}
              </div>
              <div className={css.prices}>
                {element.priceOld && (
                  <p className={css.priceOld}>{element.priceOld} ₽</p>
                )}
                <p className={css.price}>
                  {element.hasSkus && element.priceOld && 'от '}
                  {element.price} ₽
                </p>
              </div>
            </div>
          </div>

          <figure className={css.hover}>
            {element.hoverImage ? (
              <div className={css.hover_wrapper}>
                <Image
                  src={element.hoverImage}
                  alt={element?.hoverText}
                  layout="fill"
                  objectFit="cover"
                  objectPosition="center"
                  loading="lazy"
                  sizes={sizes}
                />
              </div>
            ) : (
              <img src={PRODUCT_NO_IMAGE} />
            )}

            <p className={cx(css.goto)}>{element?.hoverText}</p>
          </figure>
        </a>
      </Link>
      {element.hasSkus ? (
        <Link href={element.link} passHref>
          <a
            className={cx(
              css.cartQuick__mobile,
              isGrid && css.cartQuick__mobile_grid,
            )}
          >
            <span>→ </span> {element?.hoverText}
          </a>
        </Link>
      ) : (
        <button
          type="button"
          title={`Добавить в корзину ${element.name}`}
          className={cx(
            css.cartQuick__mobile,
            isGrid && css.cartQuick__mobile_grid,
          )}
          onClick={() => {
            if (loading) {
              return
            }
            const name = element.name
            const id = element.bx_id
            ym('reachGoal', 'km_add_to_cart_from_detail_page')
            add(
              element?.id,
              { name, id, price: element.price, quantity: 1, category },
              1,
            )
          }}
        >
          {loading && addingProduct === element?.id ? (
            <Loader />
          ) : (
            `+ В корзину`
          )}
        </button>
      )}
    </li>
  )
}

export default Card
